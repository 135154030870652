import React, { Component } from 'react';
import { FaCheck } from 'react-icons/fa';
import './Activities.css';


export default class Activities extends Component {
    
    render() {
        return (
            <div className="">
                <div className="container-fluid principal mt-5">
                        <div className="imagen wow animate__animated animate__fadeInUp" > 
                            <img className="design" id="" src={process.env.PUBLIC_URL + '/img/Activities/plano.jpg'} alt="Gancho" />
                        </div>
                        <div className="contain-text">
                            <h1 className="text wow animate__animated animate__fadeInUp">Liderando el camino en la ingeniería y la arquitectura</h1>
                            <ul className="sub-text">
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Análisis</li>
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Cálculo</li>
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Diseño estructural</li>
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Dirección general de obra</li>
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Proyecto</li>
                                <li className="wow animate__animated animate__fadeInUp">< FaCheck /> Construcción </li>
                            </ul>
                        </div>
                </div>
                
                <div className="container-fluid Activities bg-light mt-5">
                    <div className="container pt-5 pb-5">
                        <div className="row align-items-center justify-content-center pt-md-5 pt-sm-1 pb-md-5 pb-sm-1">
                            <div className="col-md-4">
                                <center>
                                    <img className="wow animate__animated animate__fadeInUp" id="" src={process.env.PUBLIC_URL + '/img/Activities/construccion.png'} alt="Construccion" />
                                    <h2 className="pt-3 pb-3 wow animate__animated animate__fadeInUp">Construcción</h2>
                                    <p className="text-secondary wow animate__animated animate__fadeInUp">Los edificios no solo tienen que ser elegantes y estéticos. Nuestras construcciones siempre piensan en sus habitantes, la pieza clave de cualquier estructura.</p>
                                </center>
                            </div>
                            <div className="col-md-4">
                                <center>
                                    <img className="wow animate__animated animate__fadeInUp" id="" src={process.env.PUBLIC_URL + '/img/Activities/ingenieria.png'} alt="Ingenieria" />
                                    <h2 className="pt-3 pb-3 wow animate__animated animate__fadeInUp">Ingeniería</h2>
                                    <p className="text-secondary wow animate__animated animate__fadeInUp">El futuro se construye hoy. Por lo que elegimos materiales de calidad para edificar. Empleamos tecnologías de vanguardia, para construcciones sustentables.</p>
                                </center>
                            </div>
                            <div className="col-md-4">
                                <center>
                                    <img className="wow animate__animated animate__fadeInUp" id="" src={process.env.PUBLIC_URL + '/img/Activities/gancho.png'} alt="Gancho" />
                                    <h2 className="pt-3 pb-3 wow animate__animated animate__fadeInUp">Resultados</h2>
                                    <p className="text-secondary wow animate__animated animate__fadeInUp">Toda técnica de construcción logra al fin su forma eterna, su expresión fundamental que a partir de entonces se perfecciona y afina.</p>
                                </center>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
                
        )
    }
}
