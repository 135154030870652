import React, { Component } from 'react';

import { Carousel } from 'react-bootstrap';
import Menu from '../menu/Menu';
import AboutUs from './AboutUs';
import Activities from './Activities';
import Projects from './Projects';
import Contact from './Contact';
import './Home.css';
import WOW from 'wowjs';

export default class Home extends Component {
    componentDidMount() {
        new WOW.WOW({
            live: false
        }).init();
    }
    render() {
        return (
            <div>
                < Menu />
                <div className="Home animate__animated animate__zoomIn">
                    <Carousel fade wrap="true">
                        <Carousel.Item interval={5000}>
                            <img
                                className="d-block w-100"
                                src={process.env.PUBLIC_URL + '/img/Carousel/carousel1.jpg'}
                                alt="First slide"
                            />
                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <img
                                className="d-block w-100"
                                src={process.env.PUBLIC_URL + '/img/Carousel/carousel2.jpg'}
                                alt="Second slide"
                            />

                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                        <Carousel.Item interval={5000}>
                            <img
                                className="d-block w-100"
                                src={process.env.PUBLIC_URL + '/img/Carousel/carousel3.jpg'}
                                alt="Third slide"
                            />

                            <Carousel.Caption>
                            </Carousel.Caption>
                        </Carousel.Item>
                    </Carousel>
                    < AboutUs />
                    < Activities />
                    < Projects />
                    < Contact />
                </div>
            </div>
                
        )
    }
}

//export default Home;