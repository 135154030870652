import React, { Component } from 'react';
import { FaPhone, FaEnvelope,FaMapMarkedAlt } from 'react-icons/fa';
import './Contact.css';

export default class Projects extends Component {
    render() {
        return (
            <div className="">
                <div className="container-fluid contact">
                    <h1 className="text-center mt-5 mb-5 " id="negrita">CONTÁCTANOS</h1>
                    {/*<p>No dudes en contactarnos, en VASA construcciones siempre estaremos para ofrecerte los mejores servicios con la mejor calidad.</p>*/}
                    <div className="row">
                        <div className="col-lg-8 ">
                            <div className="row">
                            <h2 className="text-center" id="negrita">Direcciones</h2>
                                <div className="col-sm-6">
                                    <div className="d-flex flex-column justify-content-center">
                                        <iframe className="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14931.621597923171!2d-103.4307854504963!3d20.6734273670734!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428aebd03cc23f5%3A0xbb362a90da56f4a7!2sC.%20George%20Bizet%205505%2C%20La%20Estancia%2C%2045030%20Zapopan%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1631241381841!5m2!1ses-419!2smx" allowfullscreen="" loading="lazy"></iframe>
                                        <center className="mt-3">
                                            <img className="rounded logo-contact justify-content-center" src={process.env.PUBLIC_URL + '/img/logo/Logo.png'} alt="Logo namek"></img>
                                            <h5 id="negrita">Grupo Namek</h5>
                                            <h6><FaMapMarkedAlt /> C. George Bizet 5505, La Estancia, Zapopan, Jal.</h6>
                                            <a id="tel" href="tel:331-983-6874"><FaPhone /> 331-983-6874</a>
                                        </center>
                                    </div>
                                </div>
                                <div className="col-sm-6">
                                    <div className="d-flex flex-column justify-content-center">
                                        <iframe className="mapa" src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3732.246865914645!2d-103.38318058524264!3d20.700197686177283!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8428ae37c5140bb7%3A0xb2db27470dd2cf44!2sCalle%20Ontario%201546-1%C2%AA%20Sec%2C%20Providencia%203a.%20Secc%2C%2044630%20Guadalajara%2C%20Jal.!5e0!3m2!1ses-419!2smx!4v1631241554056!5m2!1ses-419!2smx" allowfullscreen="" loading="lazy"></iframe>
                                        <center className="mt-3">
                                            <img className="rounded logo-contact justify-content-center" src={process.env.PUBLIC_URL + '/img/logo/LogoBurboa.png'} alt="Logo namek"></img>
                                            <h5 id="negrita">Grupo Burboa</h5>
                                            <h6><FaMapMarkedAlt /> C. Ontario 1546, Providencia 1ª Sec, Guadalajara, Jal.</h6>
                                            <a id="tel" href="tel:333-817-2222"><FaPhone /> 333-817-2222</a>&nbsp;&nbsp;&nbsp;&nbsp;<a id="tel" href="tel:333-817-7891"><FaPhone /> 333-817-7891</a>
                                            <p><FaEnvelope /> proyectos@gruponamek.com</p>
                                        </center>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-4 d-flex justify-content-center align-items-center bg-light">
                            <form action="" method="POST" className="">
                                <center>
                                <h2 id="negrita">Formulario de contacto</h2>
                                    <div className="row pt-3 pb-3">
                                        <div className="col-12">
                                        <label for="Nombre">Nombre</label>
                                        <input type="text" className="form-control" maxlength="40" name="Name" id="Nombre" placeholder="Nombre" required />
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-3">
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <label for="Correo">Correo</label>
                                        <input type="email" className="form-control" name="Email" id="Correo" placeholder="Correo" required />
                                        </div>
                                        <div className="col-xl-6 col-lg-6 col-md-6 col-sm-6 col-12">
                                        <label for="Telefono">Teléfono</label>
                                        <input type="text" name="Phone" id="Telefono" placeholder="Teléfono" value="" className="form-control" maxlength="10" onKeypress="if (event.keyCode < 45 || event.keyCode > 57) event.returnValue = false;" required />
                                        </div>
                                    </div>
                                    <div className="row pt-3 pb-3">
                                        <div className="col-12">
                                        <label for="Preguntas">Preguntas o comentarios</label>
                                        <textarea className="form-control" name="Message" rows="3" maxlength="100"></textarea>
                                        </div>
                                    </div>
                                    <div className="row pb-3">
                                        <div className="col-12">
                                            <button className="btn btn-primary btn-sm" type="submit">Enviar</button>
                                        </div>
                                    </div>
                                </center>
                            </form>
                        </div>
                    </div>
                </div>
            </div>       
        )
    }
}