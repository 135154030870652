import React, { Component } from 'react';
import './Projects.css';
import { Tabs,Tab } from 'react-bootstrap';

export default class Projects extends Component {
    render() {
        return (
            <div className="">
                <div className="project">
                    <div className="mt-4 mb-4 position-project">
                        <h1 className="mt-4 mb-4 title-project wow animate__animated animate__fadeInUp">NUESTROS ÚLTIMOS PROYECTOS</h1>
                        <Tabs defaultActiveKey="Todos los proyectos" id="uncontrolled-tab-example" className="mb-3 justify-content-center">
                            <Tab eventKey="Todos los proyectos" title="Todos los proyectos">
                                <div className="container">
                                    <div className="row containProcedures">
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Hospitales/RealSanJose/terminado.jpeg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">SAN JOSE VALLE REAL</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Hospital</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>      
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Residencias/Pinar/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">PINAR</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Residencia</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Edificaciones/TorreInverlat/banco2.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">INVERLAT</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Torre</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Residencias/Chapala/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">CHAPALA</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Residencia</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row containProcedures mt-3">
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Oficinas/Sana/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">SANA</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Oficina</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>      
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Oficinas/Vallejo/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">VALLEJO</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Oficina</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Hospitales" title="Hospitales">
                                <div className="container">
                                    <div className="row containProcedures">
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Hospitales/RealSanJose/terminado.jpeg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">SAN JOSE VALLE REAL</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Hospital</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>      
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Residencias" title="Residencias">
                                <div className="container">
                                    <div className="row containProcedures">
                                        <div className="col-sm-3 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Residencias/Pinar/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">PINAR</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Residencia</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Residencias/Chapala/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">CHAPALA</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Residencia</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Torres" title="Torres">
                                <div className="container">
                                    <div className="row containProcedures">
                                        <div className="col-sm-3 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Edificaciones/TorreInverlat/banco2.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">INVERLAT</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Torre</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                            <Tab eventKey="Oficinas" title="Oficinas">
                                <div className="container">
                                    <div className="row containProcedures">
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn mb-3">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Oficinas/Sana/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">SANA</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Oficina</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>      
                                        </div>
                                        <div className="col-md-3 col-sm-6 animate__animated animate__zoomIn">
                                            <div className="obra">
                                                <img src={process.env.PUBLIC_URL + '/img/Projects/Oficinas/Vallejo/terminado.jpg'} alt="Avatar" className="image rounded" />
                                                <div className="overlay">
                                                    <p className="title-overlay">VALLEJO</p>
                                                    <hr></hr>
                                                    <label className="category-overlay bg-warning p-2 rounded">Oficina</label>
                                                    <a href="!#" className="galery-overlay bg-warning p-2 rounded">Galería</a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </Tab>
                        </Tabs>
                    </div>
                    <img src={process.env.PUBLIC_URL + '/img/FondoProjects/azul1.jpg'} alt="Avatar" className="fondo-project" />
                </div>
            </div>       
        )
    }
}