import React, { Component } from 'react';
import './AboutUs.css'

export default class AboutUs extends Component {

    render() {
        return (
            <div>
                <div className="container AboutUs">
                        <div className="row align-items-center">
                            <div className="col-xl-6">
                                <h1 className="about pb-4 wow animate__animated animate__fadeInUp">
                                    <span id="normal"> Nosotros</span> 
                                    <span id="caslon"> creemos</span>
                                    <span id="normal"> en el poder de</span>
                                    <span id="italik"> las grandes ideas .</span>
                                </h1>

                                <div className="text-muted description">
                                    <h5 className="line_description pb-4 wow animate__animated animate__fadeInUp" id="description1">Somos proactivos en la búsqueda de soluciones brillantes para nuestros clientes que mejor logren sus metas y planes.</h5>
                                    <h6 className="line_description pb-4 wow animate__animated animate__fadeInUp" id="description2">Reconocemos la importancia de la innovación y el fomento de una cultura de mejora continua. El proceso de construcción de una fuerza laboral para el futuro requiere una gran capacidad de adaptación y visión.</h6>
                                </div>
                                
                            </div>
                            <div className="col-xl-6">
                                <div className="row">
                                    <div className="col-sm-6 col-12 wow animate__animated animate__fadeInUp">
                                        <img className="rounded w-100" id="" src={process.env.PUBLIC_URL + '/img/Description/banco1.jpg'} alt="Torre Inverlat 1" />
                                    </div>
                                    <div className="col-sm-6 col-12 wow animate__animated animate__fadeInUp">
                                        <img className="rounded w-100" id="inverlat2" src={process.env.PUBLIC_URL + '/img/Description/banco2.jpg'} alt="Torre Inverlat 2" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
            </div>
                
        )
    }
}
