
import React, { useState, useEffect } from "react";

import "./Menu.css";

function Header2() {
    const [header, setHeader] = useState("header");

    const listenScrollEvent = event => {
        if (window.scrollY < 33) {
            return setHeader("header");
        } else if (window.scrollY > 30) {
            return setHeader("header2");
        }
    };

    useEffect(() => {
        window.addEventListener("scroll", listenScrollEvent);

        return () => window.removeEventListener("scroll", listenScrollEvent);
    }, []);

    return (
        <header className={header}>
             <div className="container-fluid">
                 <div className="row align-items-center">
                     <div className="col-2">
                        <a href="!#" className="">
                            <img className="rounded" id="logo" src={process.env.PUBLIC_URL + '/img/logo/Logo.png'} alt="Logo"></img>
                        </a>
                     </div>
                     <div className="col-xl-5 col-md-5 col-sm-6 col-10 text-center">
                        <h3 className="titleNamek">Grupo Namek</h3>
                        <h6 className="subtitleNamek">INGENIERIAS Y ARQUITECTURAS</h6>
                        <h6 className="subtitleNamek">INMOBILIARIA</h6>
                     </div>
                 </div>
             </div>
        </header>
    );
}

export default Header2;